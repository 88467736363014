html {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
  padding: 50px;

  @include media-breakpoint-down(phone) {
    padding: 0 10px 20px;
  }
}

* {
  box-sizing: border-box;
}
