// TemplateX Typography

// Font-face

// sass-lint:disable placeholder-in-extend
// sass-lint:disable extends-before-declarations

@mixin font-family($family) {
    $familyNorm: str-replace(#{$family}, ' ');
    $familyExtend: #{'%'}font-#{$familyNorm};

    @extend #{$familyExtend};
  }

  // sass-lint:enable placeholder-in-extend
  // sass-lint:enable extends-before-declarations

  @mixin font-face(
    $family,
    $filename,
    $variants,
    $extensions,
    $append: false,
    $base: false
  ) {
    $familyNorm: str-replace(#{$family}, ' ');

    @each $variant, $name in $variants {
      @font-face {
        @include font-variant($variant);

        font-family: '#{$family}';

        @if map-has-key($extensions, eot) {
          src: url('/public/fonts/#{$familyNorm}/#{$filename}-#{$name}.eot');
        }

        src: font-face-format($extensions, $familyNorm, $filename, $name);
        font-display: swap;
      }
    }

    $extend: #{'%'}font-#{$familyNorm};
    $loaded: font-#{$familyNorm}-is-loaded;

    @if $base {
      .#{$loaded} {
        font-family: unquote('"' + $family + '"' + appended-stack($append));
      }
    }

    #{$extend} {
      .#{$loaded} & {
        font-family: unquote('"' + $family + '"' + appended-stack($append));
      }
    }
  }

  @mixin font-variant($variant) {
    @if $variant == bold {
      font-weight: bold;
      font-style: normal;
    } @else if $variant == italic {
      font-weight: normal;
      font-style: italic;
    } @else if $variant == bolditalic {
      font-weight: bold;
      font-style: italic;
    } @else if type-of($variant) == number {
      $string: inspect($variant);
      $dashIndex: str-index($string, '-');

      @if type-of($dashIndex) == number {
        $weight: str-slice($string, 0, $dashIndex - 1);
        $style: str-slice($string, $dashIndex + 1);

        font-weight: $weight;
        font-style: $style;
      } @else {
        font-weight: $variant;
        font-style: normal;
      }
    } @else {
      font-weight: normal;
      font-style: normal;
    }
  }

  // Font Rendering

  // sass-lint:disable no-vendor-prefixes

  @mixin font-rendering($rendering: null) {
    @if $rendering == thin {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    } @else {
      -moz-osx-font-smoothing: auto;
      -webkit-font-smoothing: auto;
    }
  }

  // sass-lint:enable no-vendor-prefixes

  // Font Size

  @mixin font-size($newFontSize, $lines: approx, $fallback: $remFallback) {
    $size: 0;

    @if type-of($newFontSize) == number {
      $size: $newFontSize;
    } @else {
      $size: map-get($fontSizes, $newFontSize);
    }

    @if $fallback == true {
      font-size: $size;
    }

    font-size: relative($size, $fontSize, 'rem');

    @if $lines != none and $lines != approx and $lines != 0 {
      line-height: ($lines * $lineHeight) * ($fontSize / $size);
    }

    @if $lines == 0 {
      line-height: 0;
    }

    @if $lines == approx {
      line-height:
        ($lineHeight * ceil($size / ($fontSize * $lineHeight))) *
        ($fontSize / $size);
    }
  }

  // Responsive Typography

  @mixin responsive-typo() {
    :root {
      font-size: calc(1vw + 1vh + 0.5vmin);
    }
  }

  // Hyphens

  // sass-lint:disable no-vendor-prefixes

  @mixin hyphens($hyphens: manual) {
    hyphens: $hyphens;
    hyphens: $hyphens;
    hyphens: $hyphens;
    hyphens: $hyphens;
  }

  // sass-lint:enable no-vendor-prefixes

  // Functions

  @function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
      @return str-slice($string, 1, $index - 1) + $replace +
        str-replace(
          str-slice($string, $index + str-length($search)),
          $search,
          $replace
        );
    }

    @return $string;
  }

  @function font-face-format($extensions, $familyNorm, $filename, $name) {
    $result: '';

    @each $extension, $format in $extensions {
      $comma: '';

      @if $result == '' {
        $comma: '';
      } @else {
        $comma: ', ';
      }

      @if $extension == eot {
        $result: #{$result}#{$comma}#{url('../fonts/#{$familyNorm}/#{$filename}-#{$name}.#{$extension}?#iefix')
          format('#{$format}')};
      } @else {
        $result: #{$result}#{$comma}#{url('../fonts/#{$familyNorm}/#{$filename}-#{$name}.#{$extension}')
          format('#{$format}')};
      }
    }

    @return $result;
  }

  @function appended-stack($append) {
    @return if($append, unquote(', ' + $fontFamily), '');
  }

