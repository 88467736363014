$fontVariants: (
  400: Regular,
  500: Medium,
  700: Bold,
  800: Black,
);

$fontFormats: (
  ttf: ttf,
);

$fontFamily: 'Roboto', sans-serif;

@include font-face('Roboto', Roboto, $fontVariants, $fontFormats, true, true);
